/* @font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../NotoSans/open-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
/* }
body {
  font-family: 'Noto Sans', sans-serif;
} */


*{
  font-family: 'Noto Sans', sans-serif;
}
.custom-color{
  color: '#B267CC';
  /* border: '#B267CC'; */
}

.medium-12 {
    font-size: 12px
}

.footer-terms-copyright {
  text-align: right;
}

  .medium-14 {
    font-size: 16px
  }

  .textBoxes-Styling {
        margin-bottom: 40px !important;
  }

@media only screen and (max-width: 600px) {

  .footer-terms-copyright {
  text-align: left;
}

  .custom-input {
    width: 100%;
    margin-bottom: 12px;
  }

  .section-heading {
    font-size: 22px !important;
  }

  .medium-14 {
    font-size: 14px
  }

  .numeric-25 {
    font-size: 25px
  }

  .textBoxes-Styling{
    display: grid !important
  }
  .text-fields-font{
    font-size: 13px !important;
    font-weight: solid !important;
  }

  .title-container {
    margin-bottom: 32px !important;
  }

  .page-title {
    font-size: 35px !important;
    margin-left: 15px;
  }

  .form-control-lg{
    height: 52px !important;
  }

  .textBoxes-Styling {
    margin-right: 15px;

  }

  .block-margin {
    margin-top: 10px !important;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
  .textBoxes-Styling{
    display: inline-block !important
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
  .textBoxes-Styling{
    display: inline !important
  }
}  */

#box-1 {
  /* background-image: url('../images/image.png');
  position: center center;
  background-size:cover; */
  height: auto;
  width: auto;
  padding-top: 25px;
  color: white;
  padding-bottom: 80px;
}
.textBoxes-Styling{
  display:inline-flex;
  /* display: inline; */
}
/* 
.custom-input{
  /* margin-left: 10%; */
  /* font-size: 100% */


 

.page-title {
  font-size: 45px;
  line-height: 60px;
  font-weight: 700;
}

.section-heading {
  font-size: 28px;
    font-weight: 700;
}

.sub-head {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0px;
  /* padding: 0px 28%; */
}

#box-2 {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}
.line-space{
  margin-bottom: 20px;
}
button#buy {
  border: none;
  font-weight: bold;
  font-size: 18px;
  background-color: #83009A;
}

button#buy:hover {
  background-color: rgb(137, 75, 158);
}
.MuiOutlinedInput-notchedOutline{
  border-color: #B263CD !important;
}
#box-3 {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  /* background-color: #f8f9fa; */
}

#box-4 {
    padding-top: 100px;
  padding-bottom: 100px;
}

#box-4,
#box-4-mobile {
  /* background-color: #f8f9fa; */
}

#box-4 button:hover,
#box-4-mobile button:hover {
  background-color: #e4e7eb;
  color: #007bff;
}

#box-4-mobile {
  display: none;
}

#box-5 {
  background-color: #060129;
  padding-top: 80px;
  padding-bottom: 40px;
}
.MenuList{
  position : relative !important;
  width: 40% !important;
    left: 380px !important;
    top: 70px !important;
}

/* .makeStyles-root-1{
  display: grid !important;
} */

@media screen and (max-width: 600px) {
 
  #box-1 {
    padding-bottom: 0px;
  }

  #box-4-mobile {
    display: block;
  }
  .text-fields-width{
    width: 100% !important;
  }

  .landing-para {
    margin-left: 15px;
  }
  .sub-head {
    font-size: 13px;
    padding: 0px 10px;
  }

}

.center {
  background-color: #f8f9fa;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 30px;
}

.card-body{
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 36px -20px #ccc;
  margin-bottom: 0px !important;
}

.card-title{
  font-size: 16px;
  color : black;
}

.card-button-text{
  font-weight: bolder;
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;

}

.text-uppercase {
  text-transform: uppercase!important;
  /* margin-left: 15px; */
}

.button-icon-spacing{
  padding-right: 5px;
}

.text-fields-font{
  font-size: 13px;
  font-weight: bold;
}

.text-fields-width{
  width: 220px;
  height: 3rem;
  margin: 0 1em;
}

.row{
  margin-right: 0px !important;
}

/* div#card-1{
  border: 1px solid #B263CD;
} */
.text-bold{
  font-weight: bold;
}
